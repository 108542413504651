<template>
  <v-container>
    <v-row align="center" justify="space-around">
      <div class="text--headline">
        Entity not found.
        <span class="grey--text">Redirecting...</span>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  computed: {
    ...mapState("auth", ["numOfFails"]),
  },
  methods: {
    ...mapMutations("auth", ["incrementNumOfFails"]),
    ...mapActions("auth", ["signOut"]),
    ...mapActions("entityActions", ["setDefaultRoute"]),
  },
  mounted() {
    this.incrementNumOfFails();
    if (this.numOfFails > 1) {
      this.signOut();
    } else {
      setTimeout(() => {
        this.setDefaultRoute();
      }, 3000);
    }
  }
};
</script>
